// @ts-nocheck
/* eslint-disable */

import {flowRoute} from '@/_core/util/util';
import {SOFTFACT_SERVICES} from '@modules/Core/config/services';
import {_NewSideBarRoutes, _Route, _RouteData} from '@modules/Core/types/routesTypes';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {switchInstitutionFlow} from '@modules/Institution/util/institutionUtil';
import {surveyFlowRoute} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
// import {can} from '@modules/Core/util/rolesAndPermissionsUtil';

export const routes: Record<string, _Route> = {
  dashboard: {
    flowKey: 'ProfileDashboard',
    value: 'dashboard',
    displayValue: 'base.dashboard_menu_title',
  },
  // TODO @Sherif Create Softfacts redirect flow
  softFacts: {
    flowKey: 'Softfacts',
    value: 'softfacts',
    displayValue: 'base.soft_facts_menu_title',
    pattern: SOFTFACT_SERVICES.map(service => surveyFlowRoute(service)).concat('/flow/softfacts'),
  },
  feedback: {
    flowKey: 'ThirdPersonFeedback',
    value: '360-feedback',
    displayValue: 'base.third_person_feedback_menu_title',
  },
  sharedResults: {
    flowKey: 'ProfileSharedResults',
    value: 'shared-results',
    displayValue: 'base.shared_results',
  },
  exercises: {
    flowKey: 'ProfileExercises',
    value: 'profile-exercises',
    displayValue: 'base.exercises_menu_title',
  },
  journal: {
    flowKey: 'ProfileJournals',
    value: 'journal',
    displayValue: 'base.journal',
  },
  surveys: {
    flowKey: 'CustomSurvey',
    value: 'surveys.custom',
    displayValue: 'surveys.surveys',
    featureFlag: 'custom_surveys',
  },
  teamAnalysis: {
    flowKey: 'TeamAnalysis',
    value: 'team.analysis',
    displayValue: 'service.team_reflection',
  },
  teamDevelopment: {
    flowKey: 'TeamDevelopment',
    value: 'team.development',
    displayValue: 'service.team_development_menu',
  },

  teamMembers: {
    flowKey: 'TeamMembers',
    value: 'team.members',
    displayValue: 'base.team_members',
    shouldDisplay: (data: _RouteData) =>
      can('team.overview:view', {institution_id: data.institution?.id, team_id: data.team?.id}),
  },
  teamDashboard: {
    flowKey: 'TeamDashboard',
    value: 'team.dashboard',
    displayValue: 'base.dashboard_menu_title',
    shouldDisplay: (data: _RouteData) =>
      can('team.dashboard:view', {institution_id: data.institution?.id, team_id: data.team?.id}),
  },
  newTeam: {
    flowKey: 'NewTeam',
    value: 'team.new',
    displayValue: 'organisation.create_new_team',
    shouldDisplay: (data: _RouteData) => can('organisation.teams:create', {institution_id: data.institution?.id}),
    asPopup: true,
  },

  organisationStructure: {
    flowKey: 'OrganisationStructure',
    value: 'organisation.structure',
    displayValue: 'base.institutional_hierarchy',
    shouldDisplay: (data: _RouteData) => can('organisation.overview:view', {institution_id: data.institution?.id}),
  },
  organisationDashboard: {
    flowKey: 'OrganisationDashboard',
    value: 'organisation.dashboard',
    displayValue: 'base.dashboard',
    shouldDisplay: (data: _RouteData) => can('organisation.dashboard:view', {institution_id: data.institution?.id}),
  },
  organisationMembers: {
    flowKey: 'OrganisationMembers',
    value: 'organisation.members',
    displayValue: 'base.organisation_members',
    shouldDisplay: (data: _RouteData) => can('organisation.overview:view', {institution_id: data.institution?.id}),
  },
  newOrganisation: {
    flowKey: 'NewOrganisation',
    value: 'organisation.new',
    displayValue: 'organisation.new',
    shouldDisplay: (data: _RouteData) => can('organisation.admin:create'),
    asPopup: true,
  },

  softfactionary: {
    flowKey: 'Softfactionary',
    value: 'softfactionary',
    displayValue: 'softfactionary.title',
  },
};

export const loggingRoutes: Record<string, _Route> = {
  logout: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    to: flowRoute('Logout')!,
    value: 'logout',
    pattern: '/logout',
    displayValue: 'base.logout',
  },
};

export const userMenuRoutes: Record<string, _Route> = {
  profile: {
    to: flowRoute(FLOWS.ProfileInfo),
    value: 'profile',
    pattern: flowRoute(FLOWS.ProfileInfo),
    displayValue: 'base.profile',
  },
  membership: {
    to: flowRoute(FLOWS.Payment),
    value: 'membership',
    pattern: flowRoute(FLOWS.Payment),
    displayValue: 'profile.subscriptions.displayValue',
  },
  organisation_switch: {
    onClick: () => {
      switchInstitutionFlow();
    },
    value: 'organisation.switch',
    pattern: '/settings/organisation',
    displayValue: 'institution.switch.settings_entry',
  },
  settings: {
    to: flowRoute(FLOWS.ProfileSettings),
    value: 'settings',
    pattern: flowRoute(FLOWS.ProfileSettings),
    displayValue: 'base.settings',
  },
};

const designSystemRoutes: Record<string, _Route> = {
  alerts: {
    to: '/design-system/alerts',
    value: 'Alerts',
    pattern: '/design-system/alerts',
    displayValue: 'alerts',
  },
  avatars: {
    to: '/design-system/avatars',
    value: 'Avatars',
    pattern: '/design-system/avatars',
    displayValue: 'avatars',
  },
  buttons: {
    to: '/design-system/buttons',
    value: 'Buttons',
    pattern: '/design-system/buttons',
    displayValue: 'buttons',
  },
  cards: {
    to: '/design-system/cards',
    value: 'Cards',
    pattern: '/design-system/cards',
    displayValue: 'cards',
  },
  colors: {
    to: '/design-system/colors',
    value: 'Colors',
    pattern: '/design-system/colors',
    displayValue: 'colors',
  },
  elements: {
    to: '/design-system/elements',
    value: 'Elements',
    pattern: '/design-system/elements',
    displayValue: 'elements',
  },
  icons: {
    to: '/design-system/icons',
    value: 'Icons',
    pattern: '/design-system/icons',
    displayValue: 'icons',
  },
  inputs: {
    to: '/design-system/inputs',
    value: 'Inputs',
    pattern: '/design-system/inputs',
    displayValue: 'inputs',
  },
  selects: {
    to: '/design-system/selects',
    value: 'Selects',
    pattern: '/design-system/selects',
    displayValue: 'selects',
  },
  sliders: {
    to: '/design-system/sliders',
    value: 'Sliders',
    pattern: '/design-system/sliders',
    displayValue: 'sliders',
  },
  tabs: {
    to: '/design-system/tabs',
    value: 'Tabs',
    pattern: '/design-system/tabs',
    displayValue: 'tabs',
  },
  toolTips: {
    to: '/design-system/tooltips',
    value: 'Tooltips',
    pattern: '/design-system/tooltips',
    displayValue: 'tooltips',
  },
  typography: {
    to: '/design-system/typography',
    value: 'Typography',
    pattern: '/design-system/typography',
    displayValue: 'typography',
  },
  tutorial: {
    to: '/design-system/tutorial',
    value: 'Tutorial',
    pattern: '/design-system/tutorial',
    displayValue: 'tutorial',
  },
};

export const SideBarRoutes: _NewSideBarRoutes = {
  profile: {
    items: [
      routes.dashboard, // these are regular routes
      routes.softFacts,
      routes.feedback,
      routes.sharedResults,
      routes.exercises,
      routes.journal,
      routes.surveys,
    ],
  },
  team: {
    items: [
      {
        type: 'team',
        value: 'team',
        collapsable: true,
        displayValue: ({team}) => `${team?.name}`,
        items: [routes.teamDashboard, routes.teamAnalysis, routes.teamDevelopment, routes.teamMembers],
        default: routes.teamAnalysis,
      },
      routes.newTeam,
    ],
  },
  organisation: {
    items: [
      {
        type: 'organisation',
        value: 'organisation',
        collapsable: true,
        displayValue: ({institution}) => `${institution?.name}`,
        items: [routes.organisationDashboard, routes.organisationStructure, routes.organisationMembers],
        default: routes.organisationStructure,
      },
      routes.newOrganisation,
    ],
  },
  general: {
    items: [routes.softfactionary],
  },
};
